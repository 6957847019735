<div class="appCart___light">
  <div class="confirmDiscount__title">
    {{ api.title | translate: { code: api.cart?.discount?.code } }}
  </div>
  <div tmd="{{ api.content }}"></div>
  <app-cart [cart]="api.cart" iconColor="dark" />
  <div>
    {{ api.footer | translate }}
  </div>
</div>
