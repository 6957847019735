import { Component } from '@angular/core'
import { CartState } from '@naturalcycles/shared'

@Component({
  selector: 'app-confirm-discount',
  templateUrl: './confirm-discount.component.html',
  styleUrls: ['./confirm-discount.component.scss'],
})
export class ConfirmCartComponent {
  api!: {
    cart: CartState
    title: string
    content: string
    footer: string
  }
}
